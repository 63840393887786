<template>
  <v-app>
    <v-app-bar app color="black" dark>
      <div class="d-flex align-center">
        <p class="ma-0 pa-0" style="color:white; font-size:22px"><v-icon class="ma-0 pa-0 mt-n1 mr-2" size="30">mdi-menu</v-icon>FreeFlo</p>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
