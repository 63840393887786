<template>
  <v-row v-if="media.length > 0">
    <v-col
      v-for="item in media"
      :key="item.index"
      class="d-flex child-flex pa-10"
      :cols=numCols(media.length)
    >
      <v-img
        :src="item.remote_url"
        :lazy-src="item.preview_url"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
    props:{
        media:{
            type: Array,
            default: () => ([])
        }
    }, 
    methods: {
      numCols: (count) => {
        if(count <= 3)
          return 12/count
        if(count > 3)
          return 12/3
      }
    }
}
</script>