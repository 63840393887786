<template>
    
    <v-row no-gutters> 

        <v-col
            v-for="(item,index) in streamData" :key="index"
            class="d-flex child-flex pa-2"
            sm="12"
            md="6"
            lg="4"
            xl="3"
        >
            <display-card :data="item.data"></display-card>
        </v-col>
        <div
            rounded="lg" class="ma-0 pa-0"
            v-if="streamData && streamData.length <= 0" 
            style="width:100%; height:100%;  padding: 70px 0; text-align: center;"
        >
            <v-progress-circular
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>

    </v-row>

</template>

<script>
import displayCard from "./displayCard.vue"
export default {
    components:{
        displayCard
    },

    data: function(){
        return {
            streamData: []
        }
    },

    mounted() {
        let url1 = "https://mastodon.online/api/v1/streaming/public";
        let url2 = "https://noagendasocial.com/api/v1/streaming/public";

        this.getStreamData(url1);
        this.getStreamData(url2);
    },

    methods:{
        async getStreamData(url){

            let justLoaded = false;

            let completed = false;
        
            let _this = this;

            let remStr = "";

            fetch(url)
            .then(response => response.body)
            .then(rb => {
                const reader = rb.getReader();

                return new ReadableStream({
                    start(controller) {
                        let jsonString = "";

                        function push() {
                            reader.read().then( ({done, value}) => {

                            if (done) {
                                console.log('done', done);
                                controller.close();
                                return;
                            }

                            if(justLoaded === true){
                                controller.enqueue(value);
                            
                                let newString = Buffer.from(value).toString('utf8').trim();

                                if(newString.indexOf(":thump") > -1){
                                    let jsonArr = newString.split(":thump");
                                    jsonArr = jsonArr.map((elem) => elem.trim());
                                    newString = jsonArr.join("");
                                }

                                let aa = newString.indexOf("event: update");
                                if(aa > -1){

                                    let jsonArr1 = newString.split("event: update");
                                    jsonArr1 = jsonArr1.map((elem) => elem.trim());
                                    newString = jsonArr1.join("event: update");
                                    jsonString += newString;

                                    let jsonArrU = jsonString.split("event: update");
                                    if(jsonArrU[0] === ""){
                                        jsonArrU.shift();
                                    }
                                    
                                    
                                    if(jsonArrU.length > 1){
                                        jsonString = "event: update" +jsonArrU[0];

                                        if(jsonString.indexOf("event: delete") > -1){
                                            let jsonArr11 = jsonString.split("event: delete");
                                            jsonString = jsonArr11[0].trim();   
                                        }

                                        let d = jsonString.split("event: update")[1].trim().split(":").slice(1);
                                        try {
                                            d[0] = d[0].trim();
                                        } catch (error) {
                                            console.error(error)
                                            console.error(d)
                                        }
                                        
                                        let data = JSON.parse(d.join(":"));

                                        data["urlPath"] = url;
                                    

                                        let dataObj = {
                                            event: "update",
                                            data: data
                                        };
                                        _this.streamData.push(dataObj);

                                        remStr = "event: update" + jsonArrU.slice(1).join("event: update");
                                        completed = true;
                                    }
                                
                                }
                                else{
                                    jsonString += newString;
                                }
                               
                            }

                            if(completed){
                                jsonString = remStr;
                                completed = false;
                            }
                            
                            justLoaded = true;
                            push();
                            })
                        }
                        push();
                    }
                });
            })
            .then(stream => {
            // Respond with our stream
            return new Response(stream, { headers: { "Content-Type": "text/html" } }).text();
            })
            .then(result => {
            // Do things with result
            console.log(result);
            console.log("shamsail");
            });


        },
    }
}
</script>