<template>
    <v-card dark rounded="lg" class="ma-0 pa-0"  color="blue" style="overflow: hidden;">
        <v-row no-gutters style="flex-wrap: nowrap;">
            <v-col         
                cols="2"
                style="min-width: 100px;"
                class="flex-shrink-1 pa-5"
            >
                <v-avatar size="80">
                    <v-img 
                        :src="data.account.avatar"
                    />
                </v-avatar>
            </v-col>
            <v-col         
                cols="10"
                style="min-width: 100px; max-width=100%"
                class="flex-grow-0 flex-shrink-1 pa-5"
            >
                <a class="display_name" :href="data.account.url" target="_blank" v-html="data.account.display_name"></a><br>
                <a :href="data.account.url" target="_blank" v-html="data.account.acct"></a>
            </v-col>
        </v-row>
        <v-row no-gutters style="flex-wrap: nowrap;">
            <v-col class="ma-0 pa-5" cols="12">
                <div class="ma-0 pa-0 content" v-html="data.content"></div>
            </v-col>
        </v-row>
        <displayMedia :media="data.media_attachments"/>
    </v-card>
</template>

<script>
import  displayMedia from './displayMedia.vue'
export default {
    components:{
        displayMedia
    },
    props:{
        data:{
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style scoped>
    .display_name {
        color: white;
        font-weight: bold;
        text-decoration: none;
    }
    .content {
        /* color: white; */
    }
</style>